import {
    handleQueryResolve
} from '../utils'

export default function (luCode, DIN, range, startDate, endDate) {
  const where = []
  let attrs = []
  if (luCode) {
    attrs = [{
      param: 'luCode',
      type: 'sql.Int',
      value: luCode,
    }]

    // where = ['CODE = @luCode']
    where.push('CODE = @luCode')
  }
  if (DIN) {
    attrs.push({
      param: 'DIN',
      type: 'sql.Int',
      value: DIN, // to support like
    })

    where.push('Rx.DIN = @DIN')
  }
  if (range) {
    // attrs.push({
    //   param: 'range',
    //   type: 'sql.Int',
    //   value: range, // to support like
    // })

    where.push(`Rx.RxDate > DATEADD(month, -${range}, GetDate())`)
  }
  if (startDate) {
    where.push(`Rx.RxDate > '${startDate}'`)
  }
  if (endDate) {
    where.push(`Rx.RxDate < '${endDate}'`)
  }
  const query = `
        Select
        Fillware.dbo.Patient.LastName,
        Fillware.dbo.Patient.FirstName,
        Fillware.dbo.Rx.RXNumber,
        Convert(varchar,Fillware.dbo.Rx.RxDate,23) As RxDate,
        Fillware.dbo.PatientLU.DIN,
        Fillware.dbo.PatientLU.CODE,
        Convert(varchar,Fillware.dbo.PatientLU.dtExpried,23) as dtExpried
        From
        Fillware.dbo.PatientLU Inner Join
        Fillware.dbo.Patient On Fillware.dbo.PatientLU.PatientID = Fillware.dbo.Patient.PatientID Inner Join
        Fillware.dbo.Rx On Fillware.dbo.Rx.PatientID = Fillware.dbo.Patient.PatientID
        Where
         Fillware.dbo.PatientLU.DIN = Fillware.dbo.Rx.DIN And
        ${where.join(' AND ')}
        Group By
        Fillware.dbo.Patient.LastName,
        Fillware.dbo.Patient.FirstName,
        Fillware.dbo.Rx.RXNumber,
        Convert(varchar,Fillware.dbo.Rx.RxDate,23),
        Fillware.dbo.PatientLU.DIN,
        Fillware.dbo.PatientLU.CODE,
        Convert(varchar,Fillware.dbo.PatientLU.dtExpried,23)
        Order By
        RxDate Desc
        `
  console.log(where)

  return this.query(query, attrs).then(handleQueryResolve)
}

// AND Rx.RxDate > DATEADD(month, -3, GetDate())

// SELECT top 100
// LastName,
// FirstName,
// ParentRxNumber,
// RxID,
// CODE,
// dtExpried,
// RXNumber,
// RxDate,
// Rx.PatientID,
// Rx.DoctorID,
// Rx.DIN,
// QtyDispense,
// QtyAuthorize,
// QtyRemain,
// TotalAuthorized,
// Days,
// Hold
// FROM Fillware.dbo.Rx
// INNER JOIN Fillware.dbo.PatientLU ON Fillware.dbo.Rx.PatientID = Fillware.dbo.PatientLU.PatientID
// INNER JOIN Fillware.dbo.Patient ON Fillware.dbo.Rx.PatientID = Fillware.dbo.Patient.PatientID
// WHERE ${where.join(' AND ')}
// ORDER BY RxDate DESC
